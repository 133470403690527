import React, { FC } from "react";
import s from "./Advantage.module.scss";

interface Props {
  logo: React.ReactNode;
  title: string;
  text: string;
}

const Advantage: FC<Props> = ({ logo, title, text }) => {
  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.logo}>{logo}</div>
        <div className={s.text}>
          <div className={s.title}>
            <h2>{title}</h2>
          </div>
          <div>
            <p>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantage;
