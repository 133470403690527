import Container from "@/components/Container/Container";
import Header from "@/components/Container/Header/Header";
import Footer from "@/components/Footer/Footer";
import AdvantagesSection from "@/components/Sections/AdvantagesSection/AdvantagesSection";
import CalculatorSection from "@/components/Sections/CalculatorSection/CalculatorSection";
import FaqSection from "@/components/Sections/FaqSection/FaqSection";
import OfficesSelection from "@/components/Sections/OfficesSelection/OfficesSelection";
import Partners from "@/components/Sections/Partners/Partners";
import TutorialSection from "@/components/Sections/TutorialSection/TutorialSection";
import { SITE_NAME } from "@/utils/consts";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect } from "react";

function Main() {
  return (
    <>
      <Head>
        <title>Обмен криптовалюты {SITE_NAME} с минимальной комиссией</title>
        <meta
          name="description"
          content={`Обмен криптовалюты на наличные в сервисе ${SITE_NAME}. Минимальная комиссия. Удобные условия. Гарантируем конфиденциальность.
`}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="robots" content="indexfollow" />
        <meta name="theme-color" content="#1B1B1B"></meta>
      </Head>
      <div>
        <Header />
        <main style={{ backgroundColor: "#ffff" }}>
          <Container>
            <CalculatorSection />
            <AdvantagesSection />
            <TutorialSection />
            <OfficesSelection />
            <FaqSection />
            <Partners />
          </Container>
        </main>
        <Footer />
      </div>
    </>
  );
}

export default Main;
