import React, { FC } from "react";
import s from "./OfficeElement.module.scss";
import OfficeSVG from "@/svg/OfficeSVG";

interface Props {
  city: string;
  country: string;
}

const OfficeElement: FC<Props> = ({ city, country }) => {
  return (
    <div className={s.root}>
      <div className={s.logo}>
        <OfficeSVG />
      </div>
      <div className={s.title}>
        <span>{city}</span>, {country}
      </div>
    </div>
  );
};

export default OfficeElement;
