import React, { FC } from "react";
import s from "./GradientText.module.scss";

interface Props {
  children?: React.ReactNode;
}

const GradientText: FC<Props> = ({ children }) => {
  return <span className={s.root}>{children}</span>;
};

export default GradientText;
