import React, { FC } from "react";
import s from "./OfficesSelection.module.scss";
import GradientText from "@/UI/GradientText/GradientText";
import OfficeElement from "./components/OfficeElement/OfficeElement";

interface Props {}

const OfficesSelection: FC<Props> = ({}) => {
  const offices = [
    {
      city: "МОСКВА",
      country: "Россия",
    },
    {
      city: "САНКТ-ПЕТЕРБУРГ",
      country: "Россия",
    },
    // {
    //   city: "ДУБАЙ",
    //   country: "OAЭ",
    // },
    {
      city: "СТАМБУЛ",
      country: "Турция",
    },
    {
      city: "АНТАЛЬЯ",
      country: "Турция",
    },
    // {
    //   city: "ЕРЕВАН",
    //   country: "Армения",
    // },
    // {
    //   city: "ТБИЛИСИ",
    //   country: "Грузия",
    // },
  ];

  return (
    <div className={s.root} id="offices">
      <h3>
        Наши <GradientText>офисы</GradientText>
      </h3>
      <div className={s.content}>
        <div className={s.firstRow}>
          {offices.slice(0, 4).map((office) => (
            <OfficeElement
              key={office.city}
              city={office.city}
              country={office.country}
            />
          ))}
        </div>
        <div className={s.secondRow}>
          {offices.slice(4, offices.length).map((office) => (
            <OfficeElement
              key={office.city}
              city={office.city}
              country={office.country}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OfficesSelection;
