import React, { FC } from "react";
import s from "./Footer.module.scss";
import Logo from "../Logo/Logo";
import {
  AGREEMENTS_ROUTE,
  AML_ROUTE,
  EXCHNAGER_ROUTE,
  OFFICES_ROUTE,
  REVIEWS_ROUTE,
  RULES_ROUTE,
  SITE_EMAIL,
  SOCIAL_TELEGRAM_URL,
  SUPPORT_ROUTE,
  TELEGRAM_URL,
} from "@/utils/consts";
import Link from "next/link";
import Button from "@/UI/Button/Button";
import TgCircle from "@/svg/TgCircle";
import Email from "@/svg/Email";
import Container from "../Container/Container";
import { yOpenTg } from "@/utils/yandexMetrika";
import { roistatTelegramChat } from "@/utils/roistatMetrik";

interface Props {}

const Footer: FC<Props> = ({}) => {
  let footerElements = [
    // {
    //   id: 1,
    //   name: "Купить USDT",
    //   path: "/?&cur_from=CASHUSD&cur_to=USDTTRC20",
    // },
    // {
    //   id: 2,
    //   name: "Продать USDT",
    //   path: "/?&cur_from=USDTTRC20&cur_to=CASHUSD",
    // },
    // {
    //   id: 3,
    //   name: "Отзывы",
    //   path: "/" + REVIEWS_ROUTE,
    // },
    {
      id: 9,
      name: "Обмен валют",
      path: "/" + EXCHNAGER_ROUTE,
    },
    {
      id: 4,
      name: "Правила сервиса",
      path: RULES_ROUTE,
    },
    {
      id: 7,
      name: "Политика AML",
      path: AML_ROUTE,
    },
    {
      id: 8,
      name: "Декларация",
      path: AGREEMENTS_ROUTE,
    },
    {
      id: 5,
      name: "Наши офисы",
      path: "/" + OFFICES_ROUTE,
    },
    {
      id: 6,
      name: "Поддержка",
      path: TELEGRAM_URL,
      target: "_blank",
    },
  ];

  const tgClick = () => {
    yOpenTg();
    roistatTelegramChat();
    window.open(SOCIAL_TELEGRAM_URL, "_blank");
  };

  return (
    <footer className={s.root} id="footer">
      <Container>
        <div className={s.cont}>
          <div className={s.logo}>
            <Logo />

            <div className={s.texts}>
              <p>Вопросы и предложения</p>
              <a href={`mailto:${SITE_EMAIL}`}>
                <p className={s.email}>{SITE_EMAIL}</p>
              </a>
            </div>
          </div>
          <div className={s.nav}>
            <ul>
              {footerElements
                .slice(0, Math.floor(footerElements.length / 2))
                .map(({ id, name, path, target = "_self" }) => {
                  return (
                    <li key={id}>
                      <Link target={target} href={path}>
                        {name}
                      </Link>
                    </li>
                  );
                })}
            </ul>
            <ul>
              {footerElements
                .slice(
                  Math.floor(footerElements.length / 2),
                  footerElements.length
                )
                .map(({ id, name, path, target = "_self" }) => (
                  <li key={id}>
                    <Link target={target} href={path}>
                      {name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
