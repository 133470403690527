import React, { FC } from "react";
import s from "./TutorialElement.module.scss";
import Image from "next/image";

interface Props {
  number: string;
  title: string;
  text: string;
  logo: any;
}

const TutorialElement: FC<Props> = ({ number, text, title, logo }) => {
  return (
    <div className={s.root}>
      <div className={s.container}>
        <div className={s.icon}>
          <Image src={logo} alt="" />
        </div>
        <div className={s.content}>
          <div className={s.number}>{number}</div>
          <div className={s.title}>{title}</div>
          <div className={s.text}>{text}</div>
        </div>
      </div>
    </div>
  );
};

export default TutorialElement;
