import React, { FC } from "react";
import s from "./Logo.module.scss";

interface Props {}

const Logo: FC<Props> = ({}) => {
  return <div className={s.root}>DeCenter Exchange</div>;
};

export default Logo;
