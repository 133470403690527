function Tg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
    >
      <path
        d="M1.83033 6.06171C6.20377 4.15671 9.1194 2.90069 10.5772 2.2939C14.7444 0.561161 15.6092 0.260223 16.1741 0.250028C16.2983 0.248036 16.5749 0.278738 16.7553 0.424637C16.9053 0.547684 16.9475 0.71409 16.9686 0.830926C16.9874 0.947645 17.0131 1.21366 16.9921 1.42132C16.7671 3.79319 15.7897 9.54897 15.2928 12.2056C15.0842 13.3297 14.6694 13.7066 14.2686 13.7433C13.3967 13.8235 12.7358 13.1677 11.8921 12.6148C10.5725 11.7493 9.82721 11.2107 8.54518 10.3662C7.06393 9.3903 8.02486 8.85382 8.86861 7.97725C9.08893 7.7478 12.928 4.25679 13.0006 3.94014C13.01 3.90054 13.0194 3.75288 12.9303 3.67507C12.8436 3.59702 12.7147 3.62374 12.621 3.64483C12.4874 3.67483 10.3803 5.0689 6.29283 7.82679C5.69518 8.23788 5.15377 8.43827 4.66627 8.42772C4.13189 8.41624 3.10064 8.12491 2.33424 7.876C1.39674 7.57061 0.649082 7.40913 0.714707 6.89046C0.747519 6.62046 1.12018 6.34413 1.83033 6.06171Z"
        fill="#2396FF"
      />
    </svg>
  );
}

export default Tg;
