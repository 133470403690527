import React, { FC } from "react";
import s from "./CalculatorSection.module.scss";
import Calculator from "@/components/Calculator/Calculator";
import Tg from "@/svg/Tg";
import Button from "@/UI/Button/Button";
import GradientText from "@/UI/GradientText/GradientText";
import { SOCIAL_TELEGRAM_URL, TELEGRAM_URL } from "@/utils/consts";
import { yOpenTg, yOpenTgChannel } from "@/utils/yandexMetrika";
import { roistatTelegramChat, roistatTelegramGoal } from "@/utils/roistatMetrik";

interface Props {}

const CalculatorSection: FC<Props> = ({}) => {
  const tgClick = () => {
    yOpenTgChannel();
    roistatTelegramGoal();
    window.open(SOCIAL_TELEGRAM_URL, "_blank");
  };
  const helpClick = () => {
    yOpenTg();
    roistatTelegramChat();
    window.open(TELEGRAM_URL, "_blank");
  };
  return (
    <div className={s.root} id="exchanger">
      <div className={s.content}>
        <div className={s.text}>
          <h2>
            Обменивайте USDT и другие криптовалюты на наличные <br />
            <GradientText>DeCenter Exchange</GradientText>
          </h2>
          <p>Комфортный обменник в центре вашего города.</p>
          <p>Выгодные условия обмена и простой алгоритм работы</p>
        </div>
        <div className={s.buttons}>
          <Button variant="outline" onClick={tgClick}>
            <Tg /> Наш Telegram-канал
          </Button>
          <Button variant="blur" onClick={helpClick}>
            Уточнить курс обмена
          </Button>
        </div>
      </div>
      <Calculator />
    </div>
  );
};

export default CalculatorSection;
