import React, { FC } from "react";
import s from "./AdvantagesSection.module.scss";
import { advantages } from "./advantagesData";
import { IAdvantage } from "@/api/interfaces";
import Advantage from "./components/Advantage/Advantage";

interface Props {}

const AdvantagesSection: FC<Props> = ({}) => {
  return (
    <div className={s.root}>
      {advantages.map((advantage: IAdvantage) => {
        return (
          <Advantage
            key={advantage.title}
            logo={advantage.logo}
            title={advantage.title}
            text={advantage.text}
          />
        );
      })}
    </div>
  );
};

export default AdvantagesSection;
