import React, { FC, useEffect, useRef, useState } from "react";
import s from "./FaqSection.module.scss";
import GradientText from "@/UI/GradientText/GradientText";
import AccordionArrowSVG from "@/svg/AccordionArrowSVG";
import classNames from "classnames";

interface Props {}

const FaqSection: FC<Props> = ({}) => {
  const faqs = [
    {
      question: "Сколько в среднем стоит дизайн-проект и его реализация?",
      answer:
        "С каждым заказчиком стоимость обсуждается индивидуально и зависит, в первую очередь, от Ваших пожеланий, бюджета, комплектации проекта (под ключ или отдельные виды работ) и конкретных задач.",
    },
    {
      question: "Что такое авторский надзор?",
      answer:
        "Это контроль соответствия архитектурно-планировочных, технических, художественных решений и действий подрядчика решениям, принятым в рабочем проекте и зафиксированным в документации.",
    },
    {
      question: "Основные задачи технического заказчика",
      answer:
        "Основными задачами являются: осуществление комплекса представительских, информационных и контрольно-надзорных функций для проведения предпроектной подготовки, инженерных изысканий, работ по подготовке проектной документации, строительства и капитального ремонта, а также технической документации для сдачи объектов капитального строительства в эксплуатацию.",
    },
  ];

  const [desc, setDesc] = useState<null | { id: number }>();

  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (el: HTMLDivElement, index: number) => {
      const isActive = el.classList.contains("active");
      const textElement = el.querySelector(
        ".FaqSection_text__9kAo2"
      ) as HTMLElement | null;
      const textHeight = textElement?.offsetHeight || 0;
      const newHeight = isActive ? 70 : el.offsetHeight + textHeight + 32;
      if (!isActive) {
        el.querySelector("svg")!.style.transform = "rotate(-180deg)";
      } else {
        el.querySelector("svg")!.style.transform = "rotate(0deg)";
      }
      el.classList.toggle("active", !isActive);
      el.style.height = `${newHeight}px`;

      const otherElements = Array.from(
        rootRef.current?.querySelectorAll(".FaqSection_el__569qk") || []
      ) as HTMLDivElement[];
      otherElements.forEach((otherEl: HTMLDivElement) => {
        if (otherEl !== el && otherEl.classList.contains("active")) {
          otherEl.classList.remove("active");
          otherEl.style.height = "70px";
          otherEl.querySelector("svg")!.style.transform = "rotate(0deg)";
        }
      });
    };

    const elements = rootRef.current?.querySelectorAll(".FaqSection_el__569qk");
    if (elements) {
      Array.prototype.forEach.call(
        elements,
        (el: HTMLDivElement, index: number) => {
          el.style.height = "70px";
          //el.querySelector("svg")!.style.transform = "rotate(0deg)";

          el.onclick = () => handleClick(el, index + 1);
        }
      );
    }
  }, []);

  return (
    <div className={s.root}>
      <h3>
        Часто задаваемые <GradientText>вопросы</GradientText>
      </h3>

      <div className={s.content} ref={rootRef}>
        <div className={s.el}>
          <div className={s.title}>
            <p>Я перевел криптовалюту, но средства не получил</p>
            <AccordionArrowSVG />
          </div>
          <div className={s.text}>
            Операции по обмену криптовалют проходят только после получения 1
            подтверждения от системы. Некоторые криптовалюты из-за нагрузки
            своей сети могут занимать много времени на подтвеждения (от
            нескольких минут до 3-х часов)
          </div>
        </div>
        <div className={s.el}>
          <div className={s.title}>
            <p>Как совершить обмен?</p>
            <AccordionArrowSVG />
          </div>
          <div className={s.text}>
            Вам необходимо выбрать направление обмена ("вы отдаете" и "вы
            получаете") и заполнить заявку (ввести суммы, реквизиты,
            дополнительные данные). Нажать кнопку Обменять и следовать
            инструкциям, которые Вы увидите в окне подтверждения заявки на
            обмен. Если у Вас возникли вопросы в процессе обмена, пожалуйста,
            свяжитесь с оператором через чат онлайн помощи или нажмите на иконку
            телеграма на сайте.
          </div>
        </div>
        <div className={s.el}>
          <div className={s.title}>
            <p>Прошло 30 минут, но выплата по заявке не поступила</p>
            <AccordionArrowSVG />
          </div>
          <div className={s.text}>
            Самое главное – не переживайте. Возможно, оплата в процессе или
            ожидает подтверждения сети. Вы всегда можете написать в
            круглосуточный онлайн чат или на почту и уточнить причину, почему
            оплата по заявке не поступила или задерживается. Наши специалисты
            проверят причину и ответят Вам.
          </div>
        </div>
        <div className={s.el}>
          <div className={s.title}>
            <p>Как узнать статус моей заявки?</p>
            <AccordionArrowSVG />
          </div>
          <div className={s.text}>
            Об изменении статуса заявки Вы будете уведомлены в чате в телеграм.
            Также вы можете написать персональному менеджеру в чате.
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
