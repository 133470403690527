import s from "./Nav.module.scss";
// import Button from "@/UI/Button/Button";
// import TelegramLink from "@/UI/TelegramLink/TelegramLink";
import {
  EXCHNAGER_ROUTE,
  OFFICES_ROUTE,
  SITE_NAME,
  SUPPORT_ROUTE,
  TELEGRAM_URL,
} from "@/utils/consts";
import Link from "next/link";
import { useEffect, useState } from "react";
import cn from "classnames";
import Button from "@/UI/Button/Button";
import Tg from "@/svg/Tg";
import Logo from "../Logo/Logo";
import Image from "next/image";
import supportLogo from "@/assets/supportLogo.svg";
import { yOpenTg } from "@/utils/yandexMetrika";
import { roistatTelegramChat } from "@/utils/roistatMetrik";

const Nav = () => {
  const click = () => {
    yOpenTg();
    roistatTelegramChat();
    window.open(TELEGRAM_URL, "_blank");
  };

  const nav = [
    {
      name: "Обмен валют",
      path: EXCHNAGER_ROUTE,
    },
    {
      path: OFFICES_ROUTE,
      name: "Наши офисы",
    },
    {
      path: "",
      name: "Поддержка",
      onClick: click,
    },
  ];

  const exchange_r = [
    {
      path: EXCHNAGER_ROUTE,
      name: "Обменять",
    },
  ];

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (document) {
      const domain = document?.referrer.replace(
        // eslint-disable-next-line
        /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+).*/,
        "$1"
      );

      if (typeof window !== "undefined" && !!domain) {
        sessionStorage.setItem("referrer", domain);
      }
    }
  }, [])

  useEffect(() => {
    const body = document.body;

    if (isOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }

    return () => {
      body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <nav className={s.nav}>
      <div className={s.logo}>
        <Link className={s.logo} href="/">
          <Logo />
          {/* <LogoSVG /> */}
        </Link>
      </div>
      <div>
        <div className={s.menu}>
          <ul>
            {nav.map(({ path, name, onClick }) => (
              <li className={s.link} key={name} onClick={onClick}>
                <Link href={`/${path}`}>{name}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={s.contacts}>
          {/* <TelegramLink />
        <Button size="s" type="secondary" onClick={click}>
          Обменять
        </Button> */}
          {/* <Button variant="outline" height={46} onClick={click}>
          <Tg />
          Поддержка
        </Button> */}
          <Image src={supportLogo} alt="" onClick={click} />
        </div>
      </div>

      <div className={s.mobileMenu}>
        <div
          onClick={toggleMenu}
          className={isOpen ? [s.openNavIcon, s.navIcon].join(" ") : s.navIcon}
        >
          <span />
        </div>
        {isOpen && <div onClick={toggleMenu} className={s.blur} />}
        <div className={isOpen ? cn(s.menuContent, s.open) : s.menuContent}>
          <Link className={s.logo} href="/">
            DeCenter Exchange
            {/* <LogoSVG /> */}
          </Link>
          <ul>
            {nav.map(({ path, name, onClick }) => (
              <li onClick={toggleMenu} className={s.link} key={name}>
                <Link href={`/${path}`} onClick={onClick}>
                  {name}
                </Link>
              </li>
            ))}
          </ul>

          <div className={s.mobileContacts}>
            {/* <Button size="s" type="secondary" onClick={click}>
              Обменять
            </Button>
            <TelegramLink /> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
