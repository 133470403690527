import React, { FC } from "react";
import s from "./TutorialSection.module.scss";
import GradientText from "@/UI/GradientText/GradientText";
import TutorialElement from "./components/TutorialElement/TutorialElement";
import tutorLogo1 from "@/assets/tutor1.svg";
import tutorLogo2 from "@/assets/tutor2.svg";
import tutorLogo3 from "@/assets/tutor3.svg";

interface Props {}

const TutorialSection: FC<Props> = ({}) => {
  return (
    <div className={s.root}>
      <h3>
        Как совершить обмен <GradientText>на нашей платформе</GradientText>
      </h3>
      <div className={s.content}>
        <TutorialElement
          number="01"
          title="Оставить заявку на обмен"
          text="Выбирайте направление обмена, введите сумму сделки и ваш телеграм аккаунт для связи. Нажимайте на кнопку обменять"
          logo={tutorLogo1}
        />
        <TutorialElement
          number="02"
          title="Договориться о времени посещения"
          text="С вами свяжется наш менеджер, договорится о курсе обмена, времени встречи в офисе и закажет для вас пропуск в здание"
          logo={tutorLogo2}
        />
        <TutorialElement
          number="03"
          title="Совершить обмен в офисе"
          text="В назначенное время вас будут ожидать в офисе обменника и проведут сделку. Сделка пройдёт при личной встрече"
          logo={tutorLogo3}
        />
      </div>
    </div>
  );
};

export default TutorialSection;
