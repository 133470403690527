import React, { FC } from "react";
import s from "./Partners.module.scss";
import GradientText from "@/UI/GradientText/GradientText";
import Image from "next/image";
import exnodeLogo from "@/assets/exnode.svg";

interface Props {}

const Partners: FC<Props> = ({}) => {
  const handleClick = () => {
    window.open("https://exnode.ru/?utm_source=site&utm_medium=decenter", "_blank");
  };

  return (
    <div className={s.root}>
      <h3>
        Наши <GradientText>партнеры</GradientText>
      </h3>
      <div className={s.logos}>
        <div onClick={handleClick}>
          <Image src={exnodeLogo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Partners;
