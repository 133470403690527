import React, { FC } from "react";
import s from "./Header.module.scss";
import Container from "../Container";
import Nav from "@/components/Nav/Nav";

interface Props {}

const Header: FC<Props> = ({}) => {
  return (
    <header itemScope itemType="https://schema.org/WPHeader" className={s.root}>
      <Container>
        <Nav />
      </Container>
    </header>
  );
};

export default Header;
