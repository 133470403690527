function AccordionArrowSVG({ className }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="16"
      viewBox="0 0 28 16"
      fill="none"
      className={className}
    >
      <path
        d="M2 2L14 14L26 2"
        stroke="#1D8EFF"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AccordionArrowSVG;
